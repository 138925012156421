import { getContentBlockListName } from '..';
import { getProductElementData, trackProductElementClick, trackProductListImpressions } from '../../tracking/list';

/**
 * Initialise Single Image Wide content blocks.
 *
 * This function must be called on pages that include Single Image Wide
 * content blocks, but no more than once per page load.
 */
export const initSingleImageWideBlocks = () => {
  const observer = new IntersectionObserver((entries) => {
    for (const entry of entries) {
      if (!entry.isIntersecting) {
        continue;
      }

      // Unobserve, so we only record impressions once per block.
      observer.unobserve(entry.target);

      // Add click event listeners to each hotspot in the look and get the
      // tracking data from the DOM.
      const list = getContentBlockListName(entry.target);
      const hotspots = [...entry.target.querySelectorAll('.hotspots_position__point')]
        .sort((a, b) => b.getBoundingClientRect().x < a.getBoundingClientRect().x);
      const items = [];
      for (const [position, hotspot] of hotspots.entries()) {
        items.push(getProductElementData(hotspot, { position }));

        hotspot.addEventListener('click', (e) => {
          const product = e.target.closest('.hotspots_position__product');
          if (product) {
            trackProductElementClick(e, list, { position });
          }
        });
      }

      if (items.length > 0) {
        trackProductListImpressions({ list, items });
      }
    }
  });

  [...document.querySelectorAll('.quality-promise-singleimage--wide')]
    .filter(singleImageWide => !!singleImageWide.querySelector('.hotspots_position__points'))
    .forEach(singleImageWide => observer.observe(singleImageWide));
};
